import supabase from "@/utils/supabase";
import { isValidUUID } from "@/utils/uuid";
import { app } from "@/config";
import QR from "@/components/QR.vue";

export default {
  name: "Capture",
  components: {
    QR,
  },
  data() {
    return {
      guestInfo: null,
    };
  },
  async mounted() {
    if (isValidUUID(this.id)) {
      await this.init();
    }
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    name() {
      return this.guestInfo?.name ?? "";
    },
    session() {
      return this.guestInfo?.session ?? 1;
    },
    sessionText() {
      return this.session === 1
        ? "Sesi 1 Pukul 11.00 - 12.30 WIB."
        : "Sesi 2 Pukul 12.30 - 14.00 WIB.";
    },
  },
  methods: {
    async init() {
      try {
        const { data, error } = await supabase
          .from(app.dbName)
          .select("*")
          .eq("id", this.id);
        if (error) {
          this.$toasted.global.mwError({ message: error.message });
        } else {
          this.guestInfo = data[0];
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
